import React from 'react'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import { SelectorProps } from '../SelectorMotoSteps'
import styles from './selectorMedidas.module.scss'
import { t } from '../../../i18n'
import SVGMotoSelectorMedidas from '../Common/SVGMotoSelectorMedidas/SVGMotoSelectorMedidas'
import InputDropDown from '../../../../shared/components/Input/InputDropDown'

const SelectorMedidas = ({
  selectorMotoState,
  handleChange,
  handleSubmit,
}: SelectorProps) => {
  return (
    <section className={styles.sections}>
      <form onSubmit={handleSubmit}>
        <div className={styles.title}>{t('selector-desktop.title')}</div>
        <div className={styles.form_container}>
          <div className={styles.firstRow}>
            <div className={styles.form_dropdown}>
              <InputDropDown
                placeholder={t('search-results.select')}
                labelId="selector-moto.anchura"
                name="ancho"
                options={selectorMotoState.ancho.options}
                onChange={(e) => handleChange(e, 'ancho')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ ancho: null }}
              />
              {selectorMotoState.ancho.value && (
                <div className={styles.arrowAncho}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                  <div className={styles.thirdPart} />
                </div>
              )}
            </div>
            <div className={styles.form_dropdown}>
              <InputDropDown
                placeholder={t('search-results.select')}
                labelId="selector-moto.altura"
                name="ancho"
                options={selectorMotoState.alto.options.sort(
                  (a, b) =>
                    parseInt(a.value.toString()) - parseInt(b.value.toString())
                )}
                onChange={(e) => handleChange(e, 'alto')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ ancho: null }}
              />
              {selectorMotoState.alto.value && (
                <div className={styles.arrowAlto}>
                  <div className={styles.firstPart} />
                  <div className={styles.secondPart} />
                  <div className={styles.thirdPart} />
                </div>
              )}
            </div>
            <div className={styles.form_dropdown}>
              <InputDropDown
                placeholder={t('search-results.select')}
                labelId="selector-moto.llanta"
                name="ancho"
                options={selectorMotoState.llanta.options.sort(
                  (a, b) =>
                    parseInt(a.value.toString()) - parseInt(b.value.toString())
                )}
                onChange={(e) => handleChange(e, 'llanta')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ ancho: null }}
              />
              {selectorMotoState.llanta.value && (
                <div className={styles.arrowLlanta}>
                  <div className={styles.firstPart} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.secondRow}>
            <SVGMotoSelectorMedidas />
          </div>
        </div>

        <SelectorFooterDesktop continueButton />
      </form>
    </section>
  )
}

export default SelectorMedidas
