import React, { useEffect } from 'react'
import {
  reset,
  resetFeaturesModelo,
  selectFeatureModeloMoto,
  selectMedidaMoto,
} from '../../../context/actions/selectorMotoActions'
import {
  FeatureNameModeloMoto,
  MedidaName,
} from '../../../context/reducers/selectorMotoReducer'
import { t } from '../../../i18n'
import {
  FunnelMotoScreenProps,
  TOTAL_MOBILE_STEPS,
  TOTAL_MOBILE_STEPS_FEATURES,
} from '../SelectorMotoSteps'
import route from '../../../../../src/common/utils/route'
import LayoutSelectorMotoMobile from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMotoMobile'
import SelectorMedidasMobile from '../Mobile/SelectorMedidasMobile'

interface Props extends FunnelMotoScreenProps {
  stepActive: number
  medidaActual?: MedidaName | FeatureNameModeloMoto
}

const SelectorContainerMotoMobile = ({
  dispatch,
  nextStep,
  selectorMotoState,
  stepActive,
  medidaActual,
}: Props) => {
  const onHandleClickMedidas = (value: string) => {
    if (selectorMotoState.buscar_por_medidas) {
      dispatch(selectMedidaMoto({ name: medidaActual, value }))
    } else {
      dispatch(selectFeatureModeloMoto({ name: medidaActual, value }))
    }
    nextStep()
  }

  useEffect(() => {
    if (selectorMotoState.buscar_por_medidas) {
      if (
        !selectorMotoState.ancho.options.length &&
        !selectorMotoState.ancho.loading
      ) {
        dispatch(
          reset({
            keepValues: false,
            marca: null,
            origen: route('index'),
          })
        )
      }
    } else {
      if (!selectorMotoState.selectorModelo.marca.options.length) {
        dispatch(resetFeaturesModelo())
      }
    }
  }, [])
  return (
    <LayoutSelectorMotoMobile
      title={t('preferences.cambio_neumatico_moto')}
      subTitle={''}
      stepActive={stepActive}
      totalSteps={
        selectorMotoState.buscar_por_medidas
          ? TOTAL_MOBILE_STEPS
          : TOTAL_MOBILE_STEPS_FEATURES
      }>
      <SelectorMedidasMobile
        onHandleClick={onHandleClickMedidas}
        stepActive={stepActive}
        medidaActual={medidaActual}
        showImageTyre={selectorMotoState.buscar_por_medidas}
        title={
          selectorMotoState.buscar_por_medidas
            ? t(`selector-mobile.${medidaActual}`)
            : t(`selector-mobile.modelo_moto.${medidaActual}`)
        }
        subtitle={
          selectorMotoState.buscar_por_medidas
            ? t(`selector-mobile.medidas_usuales`)
            : ''
        }
        mostrarMas={
          selectorMotoState.buscar_por_medidas
            ? t(`selector-mobile.mas_medidas`)
            : t(`selector-mobile.mostrar_mas`)
        }
        mostrarMenos={t(`selector-mobile.menos_medidas`)}
        selectable={
          selectorMotoState.buscar_por_medidas
            ? selectorMotoState[medidaActual]
            : selectorMotoState.selectorModelo[medidaActual]
        }
      />
    </LayoutSelectorMotoMobile>
  )
}

export default SelectorContainerMotoMobile
